import * as React from 'react';
import styled from 'styled-components';
import { ChannelConfig, Channels, ReplyState } from '$state/types';
import { ContextState, Placeholder } from '$state/types/contexts';
import { Template } from '$state/types/templates';
import { SnoozeStatus } from '$state/types/snooze';
import { ChannelTabs } from '$ui/ReplyBar/Channels';
import { MessageBar } from '$ui/ReplyBar/MessageBar';
import { Actions } from '$ui/ReplyBar/Actions';
import { Templates } from '$ui/ReplyBar/Templates';
import { Placeholders } from '$ui/Placeholders';

export interface Props {
    channels: ChannelConfig[];
    send: () => void;
    sendTemplate?: (template: Template, message: string) => void;
    state: ReplyState;
    stateChanged: (state: ReplyState) => void;
    enabledChannels?: Channels[];
    stalledChannels?: Channels[];
    templatableChannels?: ChannelConfig[];
    templateContext?: ContextState | undefined;
    templates?: Template[] | undefined;
    placeholders: Array<Placeholder>;
    containerHeight: number;
    showPlaceholders?: boolean;
    integrated?: boolean;
    snooze?: SnoozeStatus | null;
}

export const ReplyBar = (props: Props) => {
    const {
        send,
        sendTemplate,
        channels,
        state,
        stateChanged,
        enabledChannels,
        stalledChannels = [],
        templatableChannels = [],
        templateContext,
        templates,
        showPlaceholders,
        placeholders,
        containerHeight
    } = props;

    const [placeholderSelected, setPlaceholderSelected] = React.useState<null | string>(null);
    const channel = channels.find((chan) => chan.channel === state.channel);
    const isStalledConversation = stalledChannels.includes(state.channel);

    const hasRequiredInput = (): boolean => {
        return (
            channel?.fields
                .filter((field) => Boolean(field.required))
                .map((field) => state.fields[field.name])
                .every((fieldValue) => Boolean(fieldValue)) ?? false
        );
    };

    const channelTemplatable = templatableChannels.some(
        (templateChannel) => templateChannel.channel == channel?.channel
    );

    const isDisabled =
        !hasRequiredInput()
        || (channel && !enabledChannels?.includes(channel.channel));

    return (
        <Container>
            {channel && (
                <>
                    <ChannelTabs
                        selectedChannel={channel}
                        channels={channels}
                        state={state}
                        stateChanged={stateChanged}
                        enabledChannels={enabledChannels}
                    />
                    <MessageBar
                        selectedChannel={channel}
                        channels={channels}
                        state={state}
                        stateChanged={stateChanged}
                        isStalledConversation={isStalledConversation}
                        placeholderSelected={placeholderSelected}
                        onSend={send}
                        disabled={isDisabled}
                        containerHeight={containerHeight}
                    />
                    <Actions
                        onSend={send}
                        disabled={isDisabled}
                    >
                        {channelTemplatable && channel &&
                            <Templates
                                channel={channel}
                                templateContext={templateContext}
                                templates={templates}
                                state={state}
                                stateChanged={stateChanged}
                                sendTemplate={sendTemplate}
                                isStalledConversation={isStalledConversation}
                            />
                        }
                        {showPlaceholders &&
                            <Placeholders
                                position={"top"}
                                placeholders={placeholders}
                                placeholderSelected={p => { setPlaceholderSelected(p) }}
                            />}
                    </Actions>
                </>
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    min-height: 100px;
    background: white;
`;
