import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { Text } from '$ui/Flo/Text';
import { Icon } from '$ui/Flo/Icon';

interface Props {
    onClick: () => void;
    error?: string | null;
    loading?: boolean;
}

export const SSOLogin = ({ onClick, loading = false, error = null }: Props) => {

    return (
        <Container>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <ButtonContainer>
                <Button
                    id="login-submit"
                    onClick={onClick}
                >
                    {
                        loading &&
                        (<><Icon
                            hue="white"
                            size={2.5}
                            opacity={1}
                            icon="Loader"
                            spin={true}
                            /> Logging in with AgilioID
                        </>)
                    }
                    {
                        !loading &&
                        (<>
                            <Icon
                                hue="white"
                                opacity={1}
                                size={2.5}
                                icon="ExternalLink"
                            />
                            Login with AgilioID
                        </>)
                    }
                </Button>
                <Text level="small">{'(Opens in new window)'}</Text>
            </ButtonContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 3 })};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 0 0 auto;
    gap: ${mix.unit({ size: 1 })};
`;

const Button = styled.button`
    ${mix.bg({ hue: 'primary', shade: '6' })};
    ${mix.padding({ padding: 2 })};
    ${mix.color({ profile: 'title', dark: true })};
    ${mix.type({ level: 'subtitle2' })};
    gap: ${mix.unit({ size: 1 })};
    border: 0;
    border-radius: 3px;
    width: ${mix.unit({ size: 40 })};
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`;

const ErrorMessage = styled.div`
    ${mix.bg({ hue: 'red', shade: '6' })};
    ${mix.color({ profile: 'title', dark: true })};
    ${mix.padding({ padding: 2 })};
    ${mix.type({ level: 'subtitle2' })};
`;
