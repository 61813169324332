/* eslint-disable react/prop-types */
import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from '@xstate5/react';
import { actor } from './fsm';
import { remove } from '$state/cache';

const forceRefresh = async (): Promise<void> => {
    remove(); // remove local state cache

    // make URL relative to sanitize
    const url = window.location.href.replace(/(^[a-z]+:\/\/(.*?))?\//, '/');

    await fetch(url, {
        headers: new Headers({
            Pragma: 'no-cache',
            Expires: '-1',
            'Cache-Control': 'non-cache',
        }),
    });

    window.location.reload();
};
export const VersionedRoute = ({
    children,
    component: Component,
    ...rest
}: React.ComponentProps<typeof Route>) => {
    const refreshScheduled = useSelector(actor, (snapshot) =>
        snapshot.matches('refreshScheduled'),
    );

    return (
        <Route
            {...rest}
            render={(props) => {
                if (refreshScheduled) {
                    forceRefresh().then(() => console.log('Version mismatch'));
                }

                return <>{Component ? <Component {...props} /> : children}</>;
            }}
        />
    );
};
