import { Client, ClientSettings, Config } from './types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CommEventUnion } from '$types';
import { CommEventUnion as CommEventUnionV2 } from '$types/timeline-v2';
import * as api from '$api';
import { LastConversationPayload } from '$api';
import { EventPayload as EventPayloadV2 } from '@/types/timeline-v2';
import { featureEnabled } from '$state/queries/features';
import { RootState } from '$state/store';

enum Event {
    CONFIGURED = 'configured',
    AUTHED = 'authed',
    INITIALIZED = 'initialized',
    UNAUTHED = 'unauthed',
}

export const configured = createAction<Config>(Event.CONFIGURED);

export const authenticated = createAction<{
    client: Client;
    token: string;
    features: string[];
    role: string;
    subscriber_id: string;
}>(Event.AUTHED);

export const initialized = createAction<{
    client: Client;
    settings: ClientSettings;
    features: string[];
    role: string;
    pipeline: string;
    labels: string[];
    labels_history: string[];
    tx_types: string[];
    subscriber_id: string | null;
}>(Event.INITIALIZED);

export const unauthenticated = createAction(Event.UNAUTHED);

/**
 * Published when an action has been updated in the API
 */
export const actionUpdated = createAction<{
    patientId: string;
    stage: string;
    nextActionAt: string;
}>('actionUpdated');

/**
 * Published when an action has been completed in the UI, with the patient ID
 */
export const actionCompleted = createAction<{
    patientId: string;
}>('actionCompleted');

export const actionWaking = createAction<{
    patientId: string;
    stage: string;
}>('actionWaking');

/**
 * Published when a communication occurs between
 * a patient and Leadflo/the practice
 */
export const communicationOccurred = createAsyncThunk<
    LastConversationPayload,
    {
        patientId: string;
        communication: CommEventUnion | CommEventUnionV2;
        inbound: boolean;
    }
>('communicationOccurred', (arg) => {
    return api.fetchConversation(arg.patientId);
});

export interface TimelineChangedPayload {
    event: api.EventPayload | EventPayloadV2;
    version: number;
}
export const timelineChanged = createAsyncThunk<
    TimelineChangedPayload,
    {
        patientId: string;
        commId: string;
        event?: api.EventPayload | EventPayloadV2;
    }
>('timelineChanged', (arg, ThunkAPI) => {
    const state = ThunkAPI.getState() as RootState;
    const timelineV2Enabled = featureEnabled(state, 'timeline-v2');
    if (timelineV2Enabled) {
        return api.getCommByIdV2(arg.patientId, arg.commId);
    }
    return api.getCommById(arg.patientId, arg.commId);
});

/**
 * Publishes when a communication has changed state
 */
export const commChangedState = createAction<{
    patientId: string;
    communication: CommEventUnion | CommEventUnionV2;
    state:
        | {
              name: string;
              color: string;
          }
        | string;
}>('commChangedState');

/**
 * Published when a patient has submitted a form
 */
export const formSubmitted = createAsyncThunk<LastConversationPayload, string>(
    'formSubmitted',
    (patientId) => api.fetchConversation(patientId),
);

/**
 * Published when an integration changes state
 */
export const integrationStateChanged = createAction<{
    client_id: number;
    integration: string;
    state: 'disconnected' | 'healthy' | 'unhealthy';
}>('integrationStateChanged');

/**
 * Published when a sync job changes state for an integration
 */
export const integrationSyncStateChanged = createAction<{
    id: string;
    client_id: number;
    integration: string;
    state: 'queued' | 'syncing' | 'failed' | 'complete';
    queued_at: string;
    syncing_at: string | null;
    completed_at: string | null;
    failed_at: string | null;
    error: string | null;
}>('integrationSyncStateChanged');
