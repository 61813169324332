import * as React from 'react';
import styled from 'styled-components';
import { ChannelConfig, ReplyState } from '$state/types';
import { spliceAtCursor, useFocusedField } from '@/utils/fields';
import { Field } from '$ui/ReplyBar/MessageBar/Field';
import { mix } from '$ui/Flo/util';
import { StaleConversation } from '$ui/ReplyBar/MessageBar/StaleConversation';

export interface Props {
    selectedChannel: ChannelConfig;
    channels: ChannelConfig[];
    state: ReplyState;
    stateChanged: (state: ReplyState) => void;
    isStalledConversation: boolean;
    placeholderSelected: null | string;
    onSend: () => void;
    disabled: boolean | undefined;
    containerHeight: number;
}

export const MessageBar = (props: Props) => {
    const {
        selectedChannel,
        channels,
        state,
        stateChanged,
        isStalledConversation,
        onSend,
        disabled,
        containerHeight,
        placeholderSelected = null
    } = props;

    const fieldRefs: {
        [key: string]: React.MutableRefObject<HTMLTextAreaElement | null>;
    } = {};

    const [focused, focusedRef, setFocus] = useFocusedField(fieldRefs);

    channels.forEach((chan) => {
        chan.fields.forEach((field) => {
            fieldRefs[field.name] = React.useRef<HTMLTextAreaElement>(null);
        });
    });

    const nextField = (field: string): void => {
        fieldRefs[field].current!.focus();
    };

    const onChange = (next: Partial<ReplyState>) => {
        stateChanged({
            ...state,
            ...next,
            fields: {
                ...state.fields,
                ...next.fields
            }
        });
    };

    React.useEffect(() => {
        if (placeholderSelected) {
            insertTag(placeholderSelected);
        }
    }, [placeholderSelected]);

    const insertTag = (tag: string) => {
        const updatedFocusedName = focused ?? selectedChannel?.fields[0]?.name;

        if (!updatedFocusedName) {
            return;
        }

        const updatedFocusRef = focusedRef ?? fieldRefs[updatedFocusedName];

        const spliced = spliceAtCursor(tag, updatedFocusRef);

        if (!spliced) {
            return;
        }

        onChange({
            fields: {
                [updatedFocusedName]: spliced
            }
        });

        updatedFocusRef?.current?.focus();
    };

    if (isStalledConversation) {
        return <StaleConversation />;
    }

    const onContainerClick = (e: React.MouseEvent) => {
        const target = e.target as Node;
        const channelFields = selectedChannel?.fields;
        if (target && target.nodeName !== 'TEXTAREA') {
            const focusRef= fieldRefs[channelFields[channelFields?.length - 1]?.name];
            focusRef?.current?.focus();
        }
    };

    const height = containerHeight - 94; // 90 is the height of other elements

    return <Container
        onClick={onContainerClick}
        height={height}
    >
        {selectedChannel?.fields.map((field) => {
            const ref = fieldRefs[
                field.name
                ] as React.MutableRefObject<HTMLTextAreaElement>;
            return (
                <Field
                    placeholder={field.placeholder}
                    key={field.name}
                    name={field.name}
                    multiline={field.multiline}
                    ref={ref}
                    next={field.nextField}
                    toNextField={nextField}
                    value={state.fields[field.name]}
                    onFocus={setFocus(field.name)}
                    maxLength={field.maxLength}
                    onSend={onSend}
                    disabled={disabled}
                    onInput={(value) =>
                        onChange({
                            fields: {
                                [field.name]: value
                            }
                        })
                    }
                />
            );
        })}
    </Container>;
};

interface ContainerProps {
    height: number;
}

const Container = styled.div<ContainerProps>`
    width: 100%;
    min-height: 102px;
    ${mix.padding({ padding: [1.5, 2, 1.5, 2] })};
    height: ${({ height }) => height}px;
    display: flex;
    flex-direction: column;
`;
