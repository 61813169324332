import * as React from 'react';
import { Icon } from '$ui/Flo/Icon';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';

interface Props {
    onDrag: (newHeight: number) => void;
    containerRef: React.RefObject<HTMLElement>;
}

export const Extender = (props: Props) => {
    const { onDrag, containerRef } = props;
    const minimalHeight = 200;
    const maximalHeight = 800;

    const dragRef = useRef<HTMLDivElement>(null);

    let startY = 0;
    let startHeight = 0;

   const activateDrag = (e: MouseEvent) => {
       e.stopPropagation();
       e.preventDefault();

       const current = containerRef.current;

       if (current !== null) {
           startY = e.clientY;
           startHeight = parseInt(window.getComputedStyle(current).height);

           document.documentElement.addEventListener('mousemove', drag, false);
           document.documentElement.addEventListener('mouseup', stopDrag, false);
       }
   };

   const drag = (e: MouseEvent) => {
       e.stopPropagation();
       e.preventDefault();

       const diff = startY - e.clientY;
       const newHeight = startHeight + diff;
       onDrag(Math.max(minimalHeight, Math.min(newHeight, maximalHeight)));
   };

   const stopDrag = () => {
       document.documentElement.removeEventListener('mousemove', drag, false);
       document.documentElement.removeEventListener('mouseup', stopDrag, false);
   };

    useEffect(() => {
        if (dragRef.current) {
            dragRef.current.addEventListener('mousedown', activateDrag, false);

            return () => {
                dragRef.current && dragRef.current.removeEventListener('mousedown', activateDrag, false);
            }
        }

        return undefined;
    }, [dragRef, onDrag, containerRef]);

    return <Container ref={dragRef}>
        <Icon
            size={3}
            icon="Minus"
            hue="grey"
            opacity={1}
            cursor="row-resize"
        />
    </Container>;
};

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: row-resize;
`;
