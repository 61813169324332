import {
    assign,
    createActor,
    emit,
    fromPromise,
    setup,
    SnapshotFrom,
} from 'xstate5';
import { AxiosError } from 'axios';
import { api, refreshCSRFToken } from '$api';
import { AuthDetails, Credentials, SSOConfig } from '@/auth/modes/types';
import { popup } from '@/auth/popup';

type LoginWithCredentialsInput = {
    email: string;
    password: string;
    allowedRedirectUrls?: string[];
};

const RESEND_INVITE_TIME_LIMIT_MS = 5 * 60 * 1000;
const REFRESH_INTERVAL_MS = 30 * 60 * 1000;

export const authMachine = setup({
    types: {
        context: {} as {
            details: AuthDetails | null;
            error: string | null;

            sso: {
                error: string;
            } | null;

            invite: {
                error: string;
            } | null;
        },

        events: {} as
            | {
                  type: 'loginWithCredentials';
                  credentials: LoginWithCredentialsInput;
                  allowedRedirectUrls: string[];
              }
            | { type: 'invite' }
            | ({ type: 'initiateSSO' } & SSOConfig)
            | { type: 'logout' }
            | { type: 'refresh' },

        emitted: {} as
            | { type: 'unauthenticated' }
            | { type: 'authenticated'; details: AuthDetails }
            | { type: 'legacy.error'; message: string }
            | { type: 'invite.error'; message: string }
            | { type: 'sso.error'; message: string },
    },

    actions: {
        emitUnauthenticatedEvent: emit({ type: 'unauthenticated' }),

        emitAuthenticatedEvent: emit(({ context }) => {
            if (!context.details) {
                throw new Error(
                    'Cannot emit authenticated event when not' +
                        ' authenticated. `context.details` cannot be null when' +
                        ' authenticated',
                );
            }

            return {
                type: 'authenticated',
                details: context.details,
            } as const;
        }),

        emitLegacyErroredEvent: emit(({ context }) => {
            if (!context.error) {
                throw new Error(
                    'Cannot emit errored event when not in `errored` state',
                );
            }

            return {
                type: 'legacy.error',
                message: context.error,
            } as const;
        }),

        emitInviteErroredEvent: emit(({ context }) => {
            if (!context.invite?.error) {
                throw new Error(
                    'Cannot emit errored event when not in `errored` state',
                );
            }

            return {
                type: 'invite.error',
                message: context.invite.error,
            } as const;
        }),

        emitSSOErroredEvent: emit(({ context }) => {
            if (!context.sso?.error) {
                throw new Error(
                    'Cannot emit errored event when not in `errored` state',
                );
            }

            return {
                type: 'sso.error',
                message: context.sso.error,
            } as const;
        }),
    },

    actors: {
        loginWithCredentials: fromPromise<
            AuthDetails,
            LoginWithCredentialsInput
        >(async ({ input }) => {
            await refreshCSRFToken();

            const { data } = await api.post<AuthDetails>('/auth/session', {
                email: input.email,
                password: input.password,
            });

            // Redirect to the page they were trying to access
            const queryParameters = new URLSearchParams(window.location.search);
            const redirectTo = queryParameters.get('redirect_to');

            if (redirectTo) {
                try {
                    const allowed = input.allowedRedirectUrls;
                    const redirectUrl = new URL(redirectTo);
                    if (allowed?.some((url) => redirectUrl.hostname === url)) {
                        window.location.href = redirectTo;
                    }
                } catch (e) {
                    console.error('Invalid redirect URL');
                }
            }

            return {
                email: data.email,
                token: data.token,
                payload: data.payload,
            };
        }),

        invite: fromPromise(async () => {
            await api.post('/auth/sso/invite');
        }),

        initiateSSO: fromPromise<AuthDetails, SSOConfig>(async ({ input }) => {
            const { apiBaseUrl } = input;

            await popup({
                baseURL: apiBaseUrl,
                url: apiBaseUrl + '/auth/sso/login',
            });

            const { data } = await api.get<AuthDetails>('/auth/session');
            return data;
        }),

        refresh: fromPromise<AuthDetails>(async () => {
            await refreshCSRFToken();
            const { data } = await api.get<AuthDetails>('/auth/session');
            return data;
        }),

        logout: fromPromise(async () => {
            await api.delete('/auth/session');
        }),
    },
}).createMachine({
    id: 'auth',

    initial: 'idle',

    context: {
        details: null,
        error: null,
        sso: null,
        invite: null,
    },

    states: {
        idle: {
            on: {
                loginWithCredentials: {
                    target: 'legacy',
                },

                initiateSSO: {
                    target: 'sso',
                },
            },
        },
        legacy: {
            initial: 'authenticating',

            states: {
                authenticating: {
                    invoke: {
                        id: 'loginWithCredentials',
                        src: 'loginWithCredentials',
                        input: ({ event }) => {
                            if (event.type !== 'loginWithCredentials') {
                                throw new Error(
                                    'loginWithCredentials actor requires the' +
                                        ' loginWithCredentials event',
                                );
                            }

                            return {
                                ...event.credentials,
                                ...event.allowedRedirectUrls,
                            };
                        },

                        onDone: {
                            target: 'authenticated.sending',
                            actions: assign(({ event }) => {
                                return {
                                    details: {
                                        token: event.output.token,
                                        email: event.output.email,
                                        payload: event.output.payload,
                                    },
                                };
                            }),
                        },

                        onError: {
                            target: 'errored',
                            actions: assign(({ event }) => {
                                const error = event.error as AxiosError<{
                                    message: string;
                                }>;

                                return {
                                    error:
                                        error.response?.data?.message ??
                                        'unknown_error',
                                };
                            }),
                        },
                    },
                },
                authenticated: {
                    initial: 'idle',

                    states: {
                        idle: {
                            on: {
                                invite: {
                                    target: 'sending',
                                    refresh: 'refreshing',
                                },
                            },
                        },

                        sending: {
                            invoke: {
                                id: 'invite',
                                src: 'invite',
                                onDone: {
                                    target: 'sent',
                                },
                                onError: {
                                    target: 'errored',
                                    actions: assign(({ event }) => {
                                        const error =
                                            event.error as AxiosError<{
                                                message: string;
                                            }>;

                                        return {
                                            invite: {
                                                error:
                                                    error.response?.data
                                                        ?.message ??
                                                    'unknown_error',
                                            },
                                        };
                                    }),
                                },
                            },
                        },

                        sent: {
                            on: {
                                refresh: 'refreshing',
                            },

                            after: {
                                [RESEND_INVITE_TIME_LIMIT_MS]: {
                                    target: 'idle',
                                },
                            },
                        },

                        errored: {
                            entry: [{ type: 'emitInviteErroredEvent' }],
                            on: {
                                invite: {
                                    target: 'sending',
                                },
                            },
                        },

                        refreshing: {
                            invoke: {
                                id: 'legacy.refreshing',
                                src: 'refresh',
                                onDone: {
                                    target: 'idle',
                                    actions: assign(({ event }) => {
                                        return {
                                            details: {
                                                token: event.output.token,
                                                email: event.output.email,
                                                payload: event.output.payload,
                                            },
                                        };
                                    }),
                                },
                                onError: {
                                    target: 'errored',
                                    actions: [
                                        assign({
                                            error: 'session_expired',
                                        }),
                                    ],
                                },
                            },
                        },
                    },
                },
                errored: {
                    entry: [{ type: 'emitLegacyErroredEvent' }],
                    on: {
                        refresh: '#auth.idle',
                        loginWithCredentials: 'authenticating',
                        initiateSSO: '#auth.sso.authenticating',
                    },
                },
            },
        },
        sso: {
            initial: 'authenticating',

            states: {
                authenticating: {
                    on: {
                        refresh: '#auth.idle',
                    },
                    invoke: {
                        id: 'initiateSSO',
                        src: 'initiateSSO',
                        input: ({ event }) => {
                            if (event.type === 'initiateSSO') {
                                return event;
                            }

                            throw new Error(
                                'Authenticating requires auth config.',
                            );
                        },
                        onDone: {
                            target: 'authenticated',
                            actions: assign(({ event }) => {
                                return {
                                    details: {
                                        token: event.output.token,
                                        email: event.output.email,
                                        payload: event.output.payload,
                                    },
                                };
                            }),
                        },
                        onError: {
                            target: 'errored',
                            actions: assign(({ event }) => {
                                const error = event.error as Error;
                                return {
                                    sso: {
                                        error: error.message,
                                    },
                                };
                            }),
                        },
                    },
                },

                refreshing: {
                    invoke: {
                        id: 'sso.refreshing',
                        src: 'refresh',
                        onDone: {
                            target: 'authenticated',
                            actions: assign(({ event }) => {
                                return {
                                    details: {
                                        token: event.output.token,
                                        email: event.output.email,
                                        payload: event.output.payload,
                                    },
                                };
                            }),
                        },
                        onError: {
                            target: 'errored',
                            actions: [
                                assign({
                                    sso: {
                                        error: 'session_expired',
                                    },
                                }),
                            ],
                        },
                    },
                },

                authenticated: {
                    on: {
                        logout: 'invalidating',
                        refresh: 'refreshing',
                    },

                    after: {
                        [REFRESH_INTERVAL_MS]: {
                            target: 'refreshing',
                        },
                    },
                },

                errored: {
                    entry: [{ type: 'emitSSOErroredEvent' }],
                    on: {
                        refresh: '#auth.idle',
                        initiateSSO: 'authenticating',
                        loginWithCredentials: '#auth.legacy.authenticating',
                    },
                },

                invalidating: {
                    entry: [{ type: 'emitUnauthenticatedEvent' }],
                    invoke: {
                        id: 'sso-invalidating',
                        src: 'logout',
                        onDone: {
                            target: '#auth.idle',
                        },
                        onError: {
                            target: 'errored',
                            actions: [
                                assign({
                                    sso: {
                                        error: 'logout_failed',
                                    },
                                }),
                            ],
                        },
                    },
                },
            },
        },
    },
});

let actor = createActor(authMachine);

const PERSISTENCE_KEY = 'leadflo__auth_machine_concurrent';

const restoredEncodedState = localStorage.getItem(PERSISTENCE_KEY);
if (restoredEncodedState) {
    actor = createActor(authMachine, {
        snapshot: JSON.parse(restoredEncodedState),
    });
}

export { actor };

actor.subscribe(() => {
    localStorage.setItem(
        PERSISTENCE_KEY,
        JSON.stringify(actor.getPersistedSnapshot()),
    );
});

let started = false;

export function reset() {
    actor = createActor(authMachine);
    actor.start();
}

export function start() {
    if (!started) {
        actor.start();
        actor.send({ type: 'refresh' });
        started = true;
    }
}

export function loginWithCredentials(
    details: Credentials,
    allowedUrls: string[],
) {
    actor.send({
        type: 'loginWithCredentials',
        credentials: {
            email: details.email,
            password: details.password,
        },
        allowedRedirectUrls: allowedUrls,
    });
}

export function initiateSSO(config: SSOConfig) {
    actor.send({ type: 'initiateSSO', ...config });
}

export function logout() {
    actor.send({ type: 'logout' });
}

export function invite() {
    actor.send({ type: 'invite' });
}

export function authenticated(snapshot?: SnapshotFrom<typeof authMachine>) {
    snapshot ??= actor.getSnapshot();
    return (
        snapshot.matches({ sso: 'authenticated' }) ||
        snapshot.matches({ sso: 'refreshing' })
    );
}

export function inviting(snapshot?: SnapshotFrom<typeof authMachine>) {
    snapshot ??= actor.getSnapshot();
    return snapshot.matches({ legacy: { authenticated: 'sending' } });
}
