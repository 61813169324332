import React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

interface SeparatorProps {
    text?: string;
}
export const Separator = (props: SeparatorProps) => {
    const { text = 'or' } = props;
    return (
        <SeparatorContainer>
            <span>{text}</span>
        </SeparatorContainer>
    );
};

const SeparatorContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        rgba(0, 0, 0, 0) calc(60% - 1px),
        currentcolor calc(60%),
        rgba(0, 0, 0, 0) calc(60% + 1px)
    );
    ${mix.type({ level: 'h3' })};
    ${mix.color({ profile: 'body' })};
    ${mix.margin({ margin: [4, 0] })};
    opacity: 0.25;

    > span {
        line-height: 1;
        flex: 0 0 auto;
        background: #f0f0f0; // should be set to background color
        padding: 0 1rem;
    }
`;
