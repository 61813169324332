import * as config from '@/config/fsm';
import { useSelector } from '@xstate5/react';

export const useIsConfigured = () => {
    return useSelector(
        config.actor,
        (state) => state.matches('started') || state.matches('refreshing'),
    );
};

export const useBaseURL = () => {
    return useSelector(
        config.actor,
        (state) => state.context.config?.api_base_url,
    );
};

export const useUIBaseURL = () => {
    return useSelector(
        config.actor,
        (state) => state.context.config?.ui_base_url,
    );
};

export const useNovuAppID = () => {
    return useSelector(
        config.actor,
        (state) => state.context.config?.novu_app_id,
    );
};

export const allowedRedirectUrls = (): string[] => {
    const urls = useSelector(
        config.actor,
        (state) => state.context.config?.allowed_redirect_urls,
    );

    return urls ? urls.split(',') : [];
};

export const useAuthMode = () => {
    return useSelector(
        config.actor,
        (state) => state.context.config?.auth_mode,
    );
};
