import React from 'react';
import { Form } from '$ui/Login/LegacyMode';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { WaitingForInvite } from './WaitingForInvite';
import { Credentials } from '@/auth/modes/types';

interface MigrationFormProps {
    error?: string | null;
    onLogin?: (credentials: Credentials) => void;
    onResendInvite?: () => void;
    inviting?: boolean;
    inviteSending?: boolean;
    isAuthenticating?: boolean;
}

export const MigrationForm = (props: MigrationFormProps) => {
    const {
        error,
        onLogin,
        onResendInvite,
        inviting,
        inviteSending,
        isAuthenticating,
    } = props;

    const resendInvite = () => {
        onResendInvite && onResendInvite();
    };

    const onSubmit = (credentials: Credentials) => {
        onLogin && onLogin(credentials);
    };

    if (inviting) {
        return (
            <WaitingForInvite
                inviteSending={inviteSending}
                resendInvite={resendInvite}
            />
        );
    }

    return (
        <Container>
            <Form
                onSubmit={onSubmit}
                error={error}
                isAuthenticating={isAuthenticating}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 3 })};
`;
