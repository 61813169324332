import React, { useContext } from 'react';
import * as Feather from 'react-feather';
import styled, { css, keyframes, ThemeContext } from 'styled-components';
import { ColorToken, Hue, Shade, ThemeDef, ThemeType } from '../types';
import { palette, scale } from '../util';
import * as Custom from '$ui/Flo/Icon/Custom/';

export type IconName = keyof typeof Feather | keyof typeof Custom;

interface Props {
    className?: string;
    size?: number;
    icon: IconName;
    hue?: Hue;
    shade?: Shade;
    color?: ColorToken;
    spin?: boolean;
    thickness?: number;
    opacity?: number;
    onClick?: (e: React.MouseEvent<unknown>) => void;
    clickable?: boolean;
    cursor?: string;
}

interface IconProps extends Props {
    theme: ThemeDef;
}

export const Icon = ({
    icon,
    className,
    size = 2,
    hue = 'black',
    shade = '6',
    color,
    opacity = 0.5,
    spin = false,
    thickness = 2,
    onClick = () => null,
    clickable = undefined,
    cursor = 'initial'
}: Props) => {
    const theme = useContext(ThemeContext) as ThemeType;

    if (Feather[icon] !== undefined) {
        return displayFeatherIcon({
            icon,
            className,
            size,
            hue,
            shade,
            color,
            opacity,
            spin,
            thickness,
            onClick,
            clickable,
            cursor,
            theme,
        });
    }

    if (Custom[icon] !== undefined) {
        return displayCustomIcon({
            icon,
            className,
            size,
            hue,
            shade,
            color,
            opacity,
            spin,
            thickness,
            onClick,
            clickable,
            cursor,
            theme,
        });
    }

    return <></>;
};

const displayFeatherIcon = ({
    theme,
    icon,
    className,
    size = 2,
    hue = 'black',
    shade = '6',
    color,
    opacity = 0.5,
    spin = false,
    thickness = 2,
    onClick = () => null,
    clickable = undefined,
    cursor = 'initial'
}: IconProps) => {
    const FeatherIcon = Feather[icon];

    if (!spin) {
        return (
            <FeatherIcon
                data-testid={`Icon-${icon}`}
                data-cy={`Icon-${icon}`}
                onClick={onClick}
                className={className}
                size={scale(theme, size)}
                color={
                    color ? `var(--${color})` : palette({ theme, hue, shade })
                }
                style={{
                    opacity,
                    cursor: clickable ? 'pointer' : cursor,
                    flex: '0 0 auto',
                    strokeWidth: `${thickness}px`,
                }}
            />
        );
    }

    return (
        <Spinner spin={spin} className={className}>
            <FeatherIcon
                data-testid={`Icon-${icon}-Spinner`}
                data-cy={`Icon-${icon}-Spinner`}
                onClick={onClick}
                size={scale(theme, size)}
                color={
                    color ? `var(--${color})` : palette({ theme, hue, shade })
                }
                style={{
                    opacity,
                    cursor: clickable ? 'pointer' : 'initial',
                    strokeWidth: `${thickness}px`,
                }}
            />
        </Spinner>
    );
};

const displayCustomIcon = ({
    theme,
    icon,
    className,
    size = 2,
    hue = 'black',
    shade = '6',
    color,
    opacity = 0.5,
    spin = false,
    thickness = 2,
    onClick = () => null,
    clickable = undefined,
    cursor = 'initial'
}: IconProps) => {
    const CustomIcon = Custom[icon];
    const style = {
        opacity,
        cursor: clickable ? 'pointer' : 'initial',
        flex: '0 0 auto',
        strokeWidth: `${thickness}px`,
    };

    if (!spin) {
        return (
            <CustomIcon
                data-testid={`Icon-Custom-${icon}`}
                data-cy={`Icon-Custom-${icon}`}
                onClick={onClick}
                className={className}
                size={scale(theme, size)}
                color={
                    color ? `var(--${color})` : palette({ theme, hue, shade })
                }
                style={style}
            />
        );
    }

    return (
        <Spinner spin={spin} className={className}>
            <CustomIcon
                data-testid={`Icon-Custom-${icon}-Spinner`}
                data-cy={`Icon-Custom-${icon}-Spinner`}
                onClick={onClick}
                size={scale(theme, size)}
                color={
                    color ? `var(--${color})` : palette({ theme, hue, shade })
                }
                style={{
                    opacity,
                    cursor: clickable ? 'pointer' : cursor,
                    strokeWidth: `${thickness}px`,
                }}
            />
        </Spinner>
    );
};

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
`;

const Spinner = styled.span<{ spin?: boolean }>`
    line-height: 0;

    ${({ spin }) =>
        spin &&
        css`
            animation-name: ${rotate};
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        `}
`;
