import { assign, createActor, fromPromise, setup } from 'xstate5';
import { fetchRetry } from '@/utils/fetch-retry';
import { Config } from '@/config/fsm';

export const VERSION_REFRESH_INTERVAL_MS = 5_000;

export const versionMachine = setup({
    types: {
        context: {} as {
            availableVersion: string | null;
            uiVersion: string;
        },

        events: {} as { type: 'check'; config: Config },

        input: {} as {
            uiVersion: string;
        },
    },

    actors: {
        fetchApiVersions: fromPromise<
            [string | null, string | null, string | null],
            Config
        >(async ({ input }) => {
            const fetch = async () => {
                const response = await fetchRetry(
                    input.api_base_url + '/health/stateless',
                );
                return response.headers.get('x-api-version');
            };

            return Promise.all([fetch(), fetch(), fetch()]);
        }),
    },
}).createMachine({
    initial: 'idle',

    context: ({ input }) => ({
        availableVersion: null,
        uiVersion: input.uiVersion,
    }),

    states: {
        idle: {
            on: {
                check: {
                    target: 'fetching',
                    guard: ({ event, context }) => {
                        const { api_version: availableVersion } = event.config;

                        if (context.uiVersion === 'local') {
                            return false;
                        }

                        return context.uiVersion !== availableVersion;
                    },
                    actions: [
                        assign(({ event }) => {
                            return {
                                availableVersion: event.config.api_version,
                            };
                        }),
                    ],
                },
            },
        },
        fetching: {
            invoke: {
                id: 'fetching',
                src: 'fetchApiVersions',
                input: ({ event }) => event.config,
                onDone: [
                    {
                        target: 'idle',
                        guard: ({ event, context }) => {
                            // We should check again later if any of the API
                            // versions are null, indicating we failed to fetch
                            // this for whatever reason
                            if (
                                event.output.some((version) => version === null)
                            ) {
                                return true;
                            }

                            const { availableVersion, uiVersion } = context;
                            const [apiVersion] = event.output;

                            // If the current API and UI version match, we don't
                            // need to refresh - the API hasn't been deployed
                            if (apiVersion === uiVersion) {
                                return true;
                            }

                            // If API version and available version do not
                            // match, this means the API isn't deployed yet. We
                            // can expect the config.json file to be deployed
                            // well before the API reaches a steady state
                            if (apiVersion !== availableVersion) {
                                return true;
                            }

                            // If all 3 versions are equal, there's a strong
                            // chance the API service is in a steady state
                            return !event.output.every(
                                (version) => version === apiVersion,
                            );
                        },
                    },
                    {
                        target: 'refreshScheduled',
                    },
                ],

                onError: {
                    target: 'idle',
                    actions: (event) => {
                        console.log('errored', event);
                    },
                },
            },
        },

        refreshScheduled: {
            type: 'final',
        },
    },
});

export const actor = createActor(versionMachine, {
    input: {
        uiVersion: __VERSION__,
    },
});

export function start() {
    actor.start();
}
