import { InboxChannelFilter, InboxStatusFilter, SortOrder } from '$types/app';
import { CommType } from '$types/patient';
import { api } from './api';

export interface FetchConversationsArgs {
    view: string;
    unread?: InboxStatusFilter;
    channel?: InboxChannelFilter;
    token: string | null;
    types?: string[];
    startAt?: string;
    endAt?: string;
    sortDirection?: SortOrder;
    newInbox?: boolean;
    starred?: boolean;
    includeSnoozed?: boolean;
}

export interface ConversationPayload {
    id: string;
    patient_id: string;
    datetime: string;
    inbound: boolean;
    first_name: string;
    last_name: string;
    channel: CommType;
    snippet: string;
    read_at?: string;
    unread_count: number;
    starred: boolean;
    snoozed: boolean;
    status?: string;
}

export interface LastConversationPayload {
    id?: string;
    patient_id: string;
    first_name: string;
    last_name: string;
    snippet: string;
    channel: CommType;
    unread: boolean;
    unread_count: number;
    last_message_at: string;
    last_message_inbound: boolean;
    cursor: Cursor;
    starred: boolean;
    snoozed: boolean;
}

export interface Cursor {
    id: string;
    time: string;
}

const PAGE_SIZE = 50;

export const fetchUnreadConversationsCount = (
    channel?: InboxChannelFilter,
    types?: string[],
    startAt?: string,
    endAt?: string,
): Promise<number> => {
    const query = {};

    if (channel) {
        query['channel'] = channel;
    }

    if (types) {
        query['tx_types'] = types;
    }

    if (startAt) {
        query['start_at'] = startAt;
    }

    if (endAt) {
        query['end_at'] = endAt;
    }

    return api
        .get('/conversations/unread/count', {
            params: query,
        })
        .then(({ data }) => data.data);
};

export const fetchStarredCount = (
    channel?: InboxChannelFilter,
    types?: string[],
    startAt?: string,
    endAt?: string,
): Promise<number> => {
    const query = {};

    if (channel) {
        query['channel'] = channel;
    }

    if (types) {
        query['tx_types'] = types;
    }

    if (startAt) {
        query['start_at'] = startAt;
    }

    if (endAt) {
        query['end_at'] = endAt;
    }

    return api
        .get('/conversations/starred/count', {
            params: query,
        })
        .then(({ data }) => data.data);
};

export const fetchSnoozedCount = (
    channel?: InboxChannelFilter,
    types?: string[],
    startAt?: string,
    endAt?: string,
): Promise<number> => {
    const query = {};

    if (channel) {
        query['channel'] = channel;
    }

    if (types) {
        query['tx_types'] = types;
    }

    if (startAt) {
        query['start_at'] = startAt;
    }

    if (endAt) {
        query['end_at'] = endAt;
    }

    return api
        .get('/conversations/snoozed/count', {
            params: query,
        })
        .then(({ data }) => data.data);
};

export const fetchConversations = (
    options: FetchConversationsArgs,
): Promise<LastConversationPayload[]> => {
    const params = {
        limit: PAGE_SIZE,
        cursor: options.token,
        unread: options.unread,
        tx_types: options.types,
        start_at: options.startAt,
        end_at: options.endAt,
        sort_direction: options.sortDirection,
        starred: options.starred,
        include_snoozed: options.includeSnoozed,
    };

    if (options.channel === 'Form Submissions') {
        return api
            .get('/conversations/form-submissions', {
                params: {
                    ...params,
                },
            })
            .then(({ data }) => data);
    }

    return api
        .get('/v2/conversations/communications', {
            params: {
                ...params,
                channel: options.channel,
            },
        })
        .then(({ data }) => data);
};

export const markConversationRead = (patientId: string) =>
    api.put(`/v3/conversations/${patientId}`);

export const markConversationUnread = (patientId: string) =>
    api.put(`/conversations/${patientId}/unread`);

export const bulkMarkConversationRead = (patientIds: string[]) =>
    api.put(`/conversations/bulk/read`, {
        patient_ids: patientIds,
        batch_size: 50,
    });

export const bulkMarkConversationUnread = (patientIds: string[]) =>
    api.put(`/conversations/bulk/unread`, {
        patient_ids: patientIds,
        batch_size: 50,
    });

export const markConversationStarred = (patientId: string) =>
    api.put(`/conversations/${patientId}/star`);

export const markConversationUnstarred = (patientId: string) =>
    api.delete(`/conversations/${patientId}/star`);

export const bulkMarkConversationStarred = (patientIds: string[]) =>
    api.put(`/conversations/bulk/star`, {
        patient_ids: patientIds,
        batch_size: 50,
    });

export const bulkMarkConversationUnstarred = (patientIds: string[]) =>
    api.put(`/conversations/bulk/unstar`, {
        patient_ids: patientIds,
        batch_size: 50,
    });

export const fetchEmail = (patientId: string, commId: string) =>
    api
        .get(`/v3/patients/${patientId}/communications/${commId}`)
        .then(({ data }) => data);

export const fetchConversation = (
    patientId: string,
): Promise<LastConversationPayload> =>
    api.get(`/patients/${patientId}/conversation`).then(({ data }) => data);
