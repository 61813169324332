import * as React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Send } from '$ui/ReplyBar/Actions/Send';

export interface Props {
    children: React.ReactNode;
    onSend: () => void;
    disabled?: boolean;
}

export const Actions = (props: Props) => {
    const {
        children,
        onSend,
        disabled
    } = props;

    return <Container>
        <Left>
            {children}
        </Left>
        <ActionHolder>
            <Info>{option()} + Enter or</Info>
            <Send onClick={onSend} disabled={disabled} />
        </ActionHolder>
    </Container>;
};

const option = () => {
    return window.navigator.userAgent.indexOf('Mac') !== -1 ? '\u2318' : 'Ctrl';
};

const Container = styled.div`
    width: 100%;
    ${mix.padding({ padding: [1.5, 2, 1.5, 2] })};
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--gray-100);
`;

const Left = styled.div`
    display: flex;
    ${mix.gap({ size: 0.5 })};
`;

const ActionHolder = styled.div`
    display: flex;
    align-items: center;
    ${mix.gap({ size: 0.5 })};
`;

const Info = styled.span`
    font-size: 12px;
`;
