import * as React from 'react';
import styled from 'styled-components';
import { Icon } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import { replaceUnderscoresWithSpaces } from '@/utils/str';
import { Tooltip } from '$ui/Flo/Tooltip';
import { useState } from 'react';

interface Props {
    name: string;
    isOpen?: boolean;
    onClick: () => void;
}

export const AccordionHeader = (props: Props) => {
    const { name, isOpen, onClick } = props;

    return (
        <Header onClick={onClick}>
            <HeaderTitle>
                <TemplateName name={name} />
            </HeaderTitle>
            <HeaderTitleIcon
                clickable
                icon={isOpen ? 'ChevronUp' : 'ChevronDown'}
                size={2}
            />
        </Header>
    );
};

interface TemplateNameProps {
    name: string;
}

const TemplateName = ({ name }: TemplateNameProps) => {
    const [hovering, setHovering] = useState(false);

    if (name === 'NOT_SUBMITTED') {

        return  <Title>
            <Holder>
                In-conversation Templates
                <TooltipIndicator
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                >
                    <Tooltip
                        size="small"
                        width={40}
                        shade="1"
                        align="left"
                        open={hovering}
                    >
                        A templates you can send via WhatsApp during a conversation but not to open a conversation
                    </Tooltip>
                    <Icon icon="Info" opacity={1} size={2} />
                </TooltipIndicator>
            </Holder>
        </Title>
    }

    return <Title>{replaceUnderscoresWithSpaces(name)} Templates</Title>;
}

const Header = styled.div`
    ${mix.padding({ padding: [0, 0, 2, 0] })};
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    user-select: none;

    &:hover {
        cursor: pointer;
    }
`;

const HeaderTitle = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
`;

const HeaderTitleIcon = styled(Icon)`
    flex: 0 0 auto;
`;

const Title = styled.div`
    position: relative;
    &::first-letter {
        text-transform: uppercase;
    }
    ${mix.type({ level: 'body2', bold: true })}
`;

const Holder = styled.div`
    display: flex;
    align-items: center;
`;

const TooltipIndicator = styled.div`
    position: relative;
    margin-top: ${mix.unit({ size: 0.5 })};
    margin-left: ${mix.unit({ size: 0.5 })};
`;
