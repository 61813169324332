import { configureStore } from '@reduxjs/toolkit';
import { initialState as initialActionState } from './concerns/actions';
import * as concerns from './concerns';
import * as cache from './cache';
import { obj } from '$utils';

export const createStore = () =>
    configureStore({
        reducer: concerns,
        preloadedState: cache.get(),
    });

export const store = createStore();

store.subscribe(() => {
    const state = store.getState();

    cache.put({
        auth: state.auth,
        features: state.features,
        actions: {
            ...initialActionState,
            ...obj.pick(state.actions, ['sort', 'filters']),
        },
    });
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
