import * as legacy from './modes/legacy';
import * as concurrent from './modes/concurrent';
import * as sso from './modes/sso';
import { Actor, AnyActorLogic, SnapshotFrom } from 'xstate5';
import { AuthDetails } from '@/auth/modes/types';

export type AuthMode = 'legacy' | 'concurrent' | 'sso';

type BaseAuthService<T extends AnyActorLogic> = {
    mode: AuthMode;
    actor: Actor<T>;
    start: () => void;
    reset: () => void;
    logout: () => void;
    authenticated: (snapshot?: SnapshotFrom<T>) => boolean;
    onAuthenticated: (cb: (details: AuthDetails) => void) => void;
    onUnauthenticated: (cb: () => void) => void;
};

const legacyService: BaseAuthService<typeof legacy.authMachine> = {
    mode: 'legacy',
    actor: legacy.actor,
    start: legacy.start,
    reset: legacy.reset,
    logout: legacy.logout,
    authenticated: legacy.authenticated,
    onAuthenticated: (cb) => {
        legacy.actor.on('authenticated', ({ details }) => cb(details));
    },
    onUnauthenticated: (cb) => {
        legacy.actor.on('unauthenticated', cb);
    },
};

const concurrentService: BaseAuthService<typeof concurrent.authMachine> = {
    mode: 'concurrent',
    actor: concurrent.actor,
    start: concurrent.start,
    reset: concurrent.reset,
    logout: concurrent.logout,
    authenticated: concurrent.authenticated,
    onAuthenticated: (cb) => {
        concurrent.actor.on('authenticated', ({ details }) => cb(details));
    },
    onUnauthenticated: (cb) => {
        concurrent.actor.on('unauthenticated', cb);
    },
};

const ssoService: BaseAuthService<typeof sso.modesMachine> = {
    mode: 'sso',
    actor: sso.actor,
    start: sso.start,
    reset: sso.reset,
    logout: sso.logout,
    authenticated: sso.authenticated,
    onAuthenticated: (cb) => {
        sso.actor.on('authenticated', ({ details }) => cb(details));
    },
    onUnauthenticated: (cb) => {
        sso.actor.on('unauthenticated', cb);
    },
};

type AuthService =
    | typeof legacyService
    | typeof concurrentService
    | typeof ssoService;

export function getAuthService(authMode: AuthMode): AuthService {
    switch (authMode) {
        case 'legacy':
            return legacyService;

        case 'concurrent':
            return concurrentService;

        case 'sso':
            return ssoService;

        default:
            throw new Error(`Unknown auth mode: ${authMode}`);
    }
}
