import { useMachine } from '@xstate/react';
import {
    ContactDetailsContext,
    fsm,
    DuplicateReportContext as TDuplicateReport,
    DetailsContext,
    NextAction,
} from '$ui/AddPatient/fsm';
import React from 'react';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { Loading } from '$ui/AddPatient/Loading';
import { ContactDetails } from './ContactDetails';
import { CountryCode } from '@/utils/phone';
import styled, { css } from 'styled-components';
import { DuplicateReport } from '$ui/AddPatient/DuplicateReport';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { MarketingDetails } from '$ui/AddPatient/MarketingDetails';
import { Footer } from '$ui/AddPatient/Footer';
import { NextActions } from '$ui/AddPatient/NextActions';
import { Confirmation } from '$ui/AddPatient/Confirmation';
import { success } from '$ui/Flo/ToastV2';
import { useId } from 'react-aria';
import { useAppDispatch, useAppSelector, useSources, withState } from '$state';
import {
    getAccountLabels,
    getRecentlyUsedLabels,
    getTxTypes,
    selectClientCountry,
} from '$state/concerns/client';
import { debounce } from '@/utils/fn';
import { openPatient } from '$state/concerns/patient';
import { Labels } from '$ui/Patient/Labels';
import { unique } from '@/utils/array';

type ConnectedAddPatientFormProps = {
    onClose: () => void;
    onDirtied: () => void;
    onComplete: () => void;
};

export const ConnectedAddPatientForm: React.FC<
    ConnectedAddPatientFormProps
> = ({ onClose, onDirtied, onComplete }) => {
    const dispatch = useAppDispatch();
    const txTypes = useAppSelector(getTxTypes);
    const leadSources = useSources();
    const accountLabels = useAppSelector(withState(getAccountLabels));
    const recentLabels = useAppSelector(withState(getRecentlyUsedLabels));
    const clientCountry = useAppSelector(withState(selectClientCountry));

    return (
        <AddPatientForm
            txTypes={txTypes}
            leadSources={leadSources}
            accountLabels={accountLabels}
            recentLabels={recentLabels}
            clientCountry={clientCountry}
            onClose={onClose}
            onDirtied={onDirtied}
            onComplete={onComplete}
            onEditPatient={(patientId: string) => {
                onComplete();
                dispatch(
                    openPatient({
                        patientId,
                    }),
                );
            }}
        />
    );
};

type AddPatientFormProps = Pick<
    ConnectedAddPatientFormProps,
    'onClose' | 'onDirtied' | 'onComplete'
> & {
    txTypes: Array<string>;
    leadSources: Array<string>;
    accountLabels: Array<string>;
    recentLabels: Array<string>;
    clientCountry: CountryCode;
    onEditPatient: (patientId: string) => void;
};

const successMessages: Record<NextAction, string> = {
    enquiry: '%name has been added as a new lead',
    booking: '%name is booked in for their consultation',
    thinkingConsult: '%name is thinking about a consultation',
    thinkingTx: '%name is thinking about treatment',
    inTx: '%name is undergoing treatment',
};

export const AddPatientForm: React.FC<AddPatientFormProps> = ({
    txTypes,
    leadSources,
    accountLabels,
    recentLabels,
    clientCountry,
    onClose,
    onDirtied,
    onComplete,
    onEditPatient,
}) => {
    const id = useId();

    const [state, send] = useMachine(fsm, {
        actions: {
            toast: ({ nextAction, details }, event) => {
                if (
                    event.type !== 'done.invoke.addPatient.saving:invocation[0]'
                ) {
                    return;
                }

                success({
                    id,
                    message: successMessages[nextAction.action].replace(
                        '%name',
                        details.contact.name,
                    ),
                    action: {
                        text: 'Open patient',
                        handler: () => onEditPatient(event.data.id),
                    },
                });

                onComplete();
            },
        },
    });

    const { details, nextAction } = state.context;

    return (
        <Container>
            <Title>
                <div>Add a new patient</div>
                <Icon
                    icon="X"
                    size={3}
                    opacity={1}
                    color="gray-600"
                    clickable
                    onClick={onClose}
                />
            </Title>
            <ConditionallyVisible visible={state.matches('details')}>
                <DetailsForm
                    txTypes={txTypes}
                    leadSources={leadSources}
                    accountLabels={accountLabels}
                    recentLabels={recentLabels}
                    clientCountry={clientCountry}
                    initial={state.matches('details.initial')}
                    searching={state.matches('details.searching')}
                    duplicatesFound={state.matches('details.duplicates')}
                    uniquePatient={state.matches('details.unique')}
                    duplicateReport={state.context.duplicates}
                    onSearch={(contactDetails) =>
                        send('search', { contactDetails })
                    }
                    onEditPatient={onEditPatient}
                    onContinue={(details) =>
                        send('continue', { data: { details } })
                    }
                    onDirtied={() => onDirtied()}
                />
            </ConditionallyVisible>

            <ConditionallyVisible visible={state.matches('nextAction')}>
                <NextActions
                    {...nextAction}
                    onContinue={(nextAction) =>
                        send('continue', { data: { nextAction } })
                    }
                    onBack={() => send('back')}
                />
            </ConditionallyVisible>

            <ConditionallyVisible visible={state.matches('confirmation')}>
                <Confirmation
                    name={details.contact.name}
                    treatmentType={details.marketing.treatmentType}
                    {...nextAction}
                    onBack={() => send('back')}
                    onContinue={() => send('confirm')}
                />
            </ConditionallyVisible>

            <ConditionallyVisible visible={state.matches('saving')}>
                <Loading
                    title="Saving"
                    summary="We are just saving this patient."
                />
            </ConditionallyVisible>
        </Container>
    );
};

type DetailsFormProps = Pick<
    AddPatientFormProps,
    | 'txTypes'
    | 'leadSources'
    | 'accountLabels'
    | 'recentLabels'
    | 'clientCountry'
> & {
    initial: boolean;
    searching: boolean;
    duplicatesFound: boolean;
    uniquePatient: boolean;
    duplicateReport: TDuplicateReport;
    onSearch: (contactDetails: ContactDetailsContext) => void;
    onEditPatient: (patientId: string) => void;
    onContinue: (details: DetailsContext) => void;
    onDirtied: () => void;
};

const DetailsForm = ({
    txTypes,
    leadSources,
    accountLabels,
    recentLabels,
    clientCountry,
    initial,
    searching,
    duplicatesFound,
    uniquePatient,
    duplicateReport,
    onSearch,
    onEditPatient,
    onContinue,
    onDirtied,
}: DetailsFormProps) => {
    const defaultContactDetails = {
        name: '',
        email: '',
        phone: '',
    };

    const form = useForm<DetailsContext>({
        mode: 'all',
        defaultValues: {
            contact: defaultContactDetails,
            marketing: {
                treatmentType: '',
                referralSource: '',
                consented: false,
            },
            labels: [],
        },
    });

    const onSearchDebounced = React.useMemo(
        () => debounce({ delay: 300 }, onSearch),
        [],
    );

    const contactDetails = form.watch('contact');

    React.useEffect(() => {
        const { dirtyFields } = form.formState;
        const fields = ['email', 'phone'] as const;

        // We do not want to search if none of the fields are dirty
        if (fields.some((value) => dirtyFields.contact?.[value])) {
            onSearchDebounced(contactDetails);
        }
    }, [...Object.values(contactDetails)]);

    React.useEffect(() => {
        if (form.formState.isDirty) {
            onDirtied();
        }
    }, [form.formState.isDirty]);

    return (
        <FormProvider {...form}>
            <Body>
                <ContactDetails
                    country={clientCountry}
                    duplicatesFoundWith={duplicateReport}
                />
                {initial && (
                    <CenteredSection>
                        We need to check if a patient with this email already
                        exists.
                    </CenteredSection>
                )}
                {searching && (
                    <CenteredSection>
                        <Loading
                            title="Searching"
                            summary="We are just checking if this patient already exists."
                        />
                    </CenteredSection>
                )}
                {duplicatesFound && (
                    <Section>
                        <DuplicateReport
                            {...duplicateReport}
                            onEditPatient={onEditPatient}
                        />
                    </Section>
                )}
                {uniquePatient && (
                    <>
                        <Section>
                            <MarketingDetails
                                txTypes={txTypes}
                                leadSources={leadSources}
                            />
                        </Section>
                        <Section>
                            <Controller
                                name="labels"
                                control={form.control}
                                render={({ field }) => {
                                    const onLabelDeleted = (label: string) => {
                                        const labels = field.value.filter(
                                            (a) => {
                                                return a !== label;
                                            },
                                        );
                                        field.onChange(labels);
                                    };

                                    const onLabelAdded = (label: string) => {
                                        const labels = [...field.value, label];
                                        field.onChange(unique(labels));
                                    };

                                    return (
                                        <Labels
                                            labels={field.value}
                                            accountLabels={accountLabels}
                                            recentLabels={recentLabels}
                                            onDelete={onLabelDeleted}
                                            onAdd={onLabelAdded}
                                        />
                                    );
                                }}
                            />
                        </Section>
                    </>
                )}
            </Body>
            {form.formState.isValid && uniquePatient && (
                <Footer
                    id="details"
                    onContinue={() => {
                        form.handleSubmit(onContinue)();
                    }}
                />
            )}
        </FormProvider>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const Title = styled.h1`
    ${mix.type({ level: 'body1', bold: true })};
    ${mix.padding({ padding: 3 })};
    color: var(--gray-600);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-100);
    ${mix.height({ size: 7 })};
`;

const ConditionallyVisible = styled.div<{ visible: boolean }>`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 56px);

    ${({ visible }) =>
        !visible &&
        css`
            display: none;
        `}
`;

const Body = styled.div`
    ${mix.padding({ padding: 3 })};
    ${mix.gap({ size: 1 })};
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

const Section = styled.div`
    ${mix.padding({ padding: [3, 0, 3] })};
    flex: 0 1 auto;
    display: flex;
    border-top: 1px solid var(--gray-100);

    &:last-child {
        padding-bottom: 0;
    }
`;

const CenteredSection = styled(Section)`
    ${mix.type({ level: 'body2' })};
    ${mix.padding({ padding: 3 })};
    color: var(--gray-400);
    text-align: center;
    align-items: center;
    justify-content: center;
`;
