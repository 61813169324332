import { assign, createActor, emit, fromPromise, setup } from 'xstate5';
import { fetchRetry } from '@/utils/fetch-retry';
import { AxiosError } from 'axios';

export type Config = {
    api_base_url: string;
    api_hostname: string;
    api_version: string;
    auth_mode: 'legacy' | 'concurrent' | 'sso';
    environment: string;
    novu_app_id: string;
    proxy_base_url: string;
    pusher_app_id: string;
    pusher_cluster: string;
    pusher_enabled: boolean;
    pusher_host: string;
    pusher_key: string;
    ui_base_url: string;
    ws_port: string;
    ws_secure: boolean;
    allowed_redirect_urls: string;
};

export const CONFIG_REFRESH_INTERVAL_MS = 15 * 60 * 1000;

export const configMachine = setup({
    types: {
        context: {} as {
            config: Config | null;
            error: string | null;
        },
        events: {} as
            | { type: 'idle' }
            | { type: 'starting' }
            | { type: 'started' }
            | { type: 'failed'; message: string }
            | { type: 'refreshing' },

        emitted: {} as
            | { type: 'started'; config: Config }
            | { type: 'refreshed'; config: Config }
            | { type: 'failed'; message: string },
    },

    actions: {
        emitStartedEvent: emit(({ context }) => {
            if (!context.config) {
                throw new Error(
                    'Config must be available when the config service is' +
                        ' in the started state',
                );
            }

            return {
                type: 'started',
                config: context.config,
            } as const;
        }),

        emitRefreshedEvent: emit(({ context }) => {
            if (!context.config) {
                throw new Error(
                    'Config must be available when the config service is' +
                        ' in the refreshing state',
                );
            }

            return {
                type: 'refreshed',
                config: context.config,
            } as const;
        }),

        emitFailedEvent: emit(({ context }) => {
            if (!context.error) {
                throw new Error(
                    'Cannot emit failed event when not in `errored` state',
                );
            }

            return {
                type: 'failed',
                message: context.error,
            } as const;
        }),
    },

    actors: {
        fetch: fromPromise(async () => {
            const response = await fetchRetry(location.origin + '/config.json');

            if (!response.ok) {
                throw new Error('Failed to fetch config');
            }

            return {
                type: 'started',
                config: await response.json(),
            } as const;
        }),
    },
}).createMachine({
    initial: 'idle',

    context: {
        config: null,
        error: null,
    },

    states: {
        idle: {
            on: {
                starting: 'starting',
            },
        },

        starting: {
            invoke: {
                id: 'fetch',
                src: 'fetch',
                onDone: {
                    target: 'started',
                    actions: [
                        assign(({ event }) => ({
                            config: event.output.config,
                        })),

                        { type: 'emitStartedEvent' },
                    ],
                },
                onError: {
                    target: 'failed',
                    actions: assign(({ event }) => {
                        const error = event.error as AxiosError<{
                            message: string;
                        }>;

                        return {
                            error:
                                error.response?.data?.message ??
                                'unknown_error',
                        };
                    }),
                },
            },
        },

        started: {
            on: {
                failed: 'failed',
            },
            after: {
                [CONFIG_REFRESH_INTERVAL_MS]: {
                    target: 'refreshing',
                },
            },
        },

        refreshing: {
            invoke: {
                id: 'refresh-fetch',
                src: 'fetch',
                onDone: {
                    target: 'started',
                    actions: [
                        assign(({ event }) => ({
                            config: event.output.config,
                        })),

                        { type: 'emitRefreshedEvent' },
                    ],
                },
                onError: {
                    // do nothing if refresh fails and try again next
                    // cycle
                    target: 'started',
                },
            },
        },

        failed: {
            entry: 'emitFailedEvent',
        },
    },
});

let actor = createActor(configMachine);
let started = false;

export function start() {
    if (!started) {
        actor.start();
        actor.send({ type: 'starting' });
    }
}

export function reset() {
    actor = createActor(configMachine);
    actor.start();
}

export { actor };
