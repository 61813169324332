import { ActionState, AuthState, FeatureState } from './types';

const KEY = 'leadflo__state';

interface CachedState {
    auth: AuthState;
    features: FeatureState;
    actions: ActionState;
}

export const put = (state: CachedState) => {
    localStorage.setItem(KEY, JSON.stringify(state));
};

export const get = (): CachedState | Record<string, never> => {
    const value = localStorage.getItem(KEY);

    if (!value) {
        return {};
    }
    try {
        const state: CachedState = JSON.parse(value);

        return {
            auth: state.auth,
            features: state.features,
            actions: state.actions,
        };
    } catch (e) {
        console.warn('Could not load state from LocalStorage: ', e);
        return {};
    }
};

export const remove = () => {
    localStorage.removeItem(KEY);
};
