import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import LogoSvg from '$resources/logo.svg';
import AgilioSvg from '$resources/agilio-group.svg';

export const Quote = () => {
    return (
        <Container>
            <QuoteBlock>
                <AgilioLogo />
                <Logo />
                <QuoteLine>Communicate.</QuoteLine>
                <QuoteLine>Automate.</QuoteLine>
                <QuoteLine>Convert.</QuoteLine>
            </QuoteBlock>
            <SignatureSvg />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const QuoteBlock = styled.div`
    margin-bottom: 24px;
    text-align: right;
`;

const QuoteLine = styled.h2`
    ${mix.color({ profile: 'body', dark: true })};
    font-family: Roboto, sans-serif;
    font-size: 48px;
    font-weight: bold;
    margin: 0;
`;

const Logo = styled(LogoSvg)`
    fill: white;
    opacity: 0.9;
`;

export const SignatureSvg = () => {
    return (
        <svg
            width="406"
            height="105"
            viewBox="0 0 406 105"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24 72.7C23.9 72.7 23.9 72.7 23.8 72.7C23.5 72.6 23.3 72.3 23.4 72C34.4 36.8 38.6 28.5 38.8 28.2C39 27.9 39.3 27.8 39.6 27.9C39.9 28.1 40 28.4 39.9 28.7C39.9 28.8 35.5 37.3 24.6 72.4C24.5 72.5 24.3 72.7 24 72.7Z"
                fill="white"
            />
            <path
                d="M27.7 53.8C24.9 53.8 20.8 53.3 19 50.7C17.6 48.7 17.8 45.8 19.7 42.2C19.9 41.9 20.2 41.8 20.5 41.9C20.8 42.1 20.9 42.4 20.8 42.7C19.1 45.9 18.9 48.3 20 50C22.4 53.4 30.2 52.4 30.3 52.4C78 42.1 75.6 26 75.6 25.8V25.7C75.6 13.4 56.9 13.2 56.7 13.2C27.5 13.5 6.3 25.8 6 25.9C5.7 26.1 5.4 26 5.2 25.7C5 25.4 5.1 25.1 5.4 24.9C5.6 24.8 27.1 12.4 56.6 12.1C56.8 12.1 76.6 12.3 76.7 25.7C76.9 27 78.4 43.2 30.4 53.7C30.3 53.7 29.2 53.8 27.7 53.8Z"
                fill="white"
            />
            <path
                d="M66.2 63.3C63 63.3 60.9 62.4 59.9 60.7C58.8 58.7 59.6 56.1 60.4 54.5C58.3 54.8 56.7 54.5 55.4 53.6C53.4 52.2 53.3 49.8 53.3 49.7C53.3 49.3 53.6 48.9 54 48.9C54.4 48.9 54.8 49.2 54.8 49.6C54.8 49.6 54.9 51.3 56.3 52.3C57.5 53.1 59.3 53.2 61.6 52.6C61.9 52.5 62.2 52.6 62.4 52.9C62.6 53.2 62.6 53.5 62.4 53.8C61.7 54.9 60.1 58.1 61.1 60C61.8 61.2 63.4 61.8 66 61.8C76.6 61.8 88.6 56.9 93.2 53.3C93.6 53 97.3 49.6 100.2 50.3C101 50.5 102 51.1 102.6 52.7C102.7 53.1 102.5 53.5 102.1 53.7C101.7 53.8 101.3 53.6 101.1 53.2C100.8 52.4 100.4 51.9 99.8 51.8C98.1 51.4 95.1 53.7 94.2 54.5C88.8 58.8 76.2 63.3 66.2 63.3Z"
                fill="white"
            />
            <path
                d="M125.2 61.5C125.2 61.5 125.1 61.5 125.2 61.5C124.9 61.5 124.6 61.3 124.5 61C123.3 58.1 122.8 55.5 122.6 54C120.2 56 114.6 60.1 109 60.1C103.6 60.1 100.7 57.8 99.6 56.5C98.5 57.8 96.2 60.1 93.7 60.1C92.5 60.1 91.9 59.6 91.5 59.1C90.5 57.6 91.4 55.2 91.5 54.9C91.7 54.5 92.1 54.3 92.5 54.4C92.9 54.5 93.1 55 93 55.4C92.8 56 92.4 57.5 92.9 58.2C93 58.3 93.1 58.5 93.8 58.5C95.9 58.5 98.4 55.7 99.1 54.7C99.3 54.5 99.5 54.4 99.8 54.4C100.1 54.4 100.3 54.6 100.4 54.8C100.5 54.9 102.6 58.5 109.1 58.5C115.8 58.5 122.7 51.9 122.8 51.8C123 51.6 123.4 51.5 123.6 51.6C123.9 51.7 124.1 52 124.1 52.3C124.1 52.3 124.2 55 125.4 58.7C126.5 56.7 128.4 53.3 129.4 52.1C129.7 51.8 130.2 51.7 130.5 52C130.5 52 132.7 53.8 136.6 54.8C140.2 55.7 146.1 56.1 153.5 53C153.9 52.8 154.4 53 154.5 53.4C154.7 53.8 154.5 54.3 154.1 54.4C141.1 59.9 132.5 55.3 130.1 53.7C128.6 55.9 125.9 61 125.9 61C125.7 61.4 125.5 61.5 125.2 61.5Z"
                fill="white"
            />
            <path
                d="M179.2 60.8C178.7 60.8 167.4 60.6 167.4 54.4C167.4 48.5 173.9 44.5 174.2 44.4C174.5 44.2 174.8 44.3 174.9 44.6C175.1 44.9 175 45.2 174.7 45.3C174.6 45.3 168.4 49.1 168.4 54.4C168.4 59.5 179 59.7 179.1 59.7C179.2 59.7 190.7 58.9 199 52.5C208 45.4 208.5 43.4 208.5 40.6C208.5 40.5 208.6 36.4 199.8 34.2L180.8 30.9C180.4 30.8 171.6 29.1 171.6 22.9C171.6 16.6 186.2 9.90001 192.1 8.90001C192.4 8.80001 200.5 7.20001 205.3 7.20001C208.9 7.20001 220.6 7.90001 220.6 16.7C220.6 16.8 221.2 21.7 212.8 26.1C212.5 26.2 212.2 26.1 212.1 25.9C212 25.6 212.1 25.3 212.3 25.2C219.9 21.2 219.5 16.9 219.5 16.9C219.5 9.00001 208.6 8.40001 205.3 8.40001C200.6 8.40001 192.4 10.1 192.3 10.1C186.6 11.1 172.7 17.6 172.7 23.1C172.7 28.5 180.9 30.1 181 30.1L200 33.4C209.7 35.9 209.5 40.7 209.5 40.9C209.5 44.3 208.5 46.6 199.6 53.6C191.2 60 179.7 60.8 179.2 60.8Z"
                fill="white"
            />
            <path
                d="M211.4 59.4C211.2 59.4 211 59.4 210.8 59.3C209.4 58.8 209.4 56.7 209.4 56C209.4 50 214.3 48.2 214.4 48.2C214.8 48.1 215.2 48.3 215.4 48.7C215.5 49.1 215.3 49.5 214.9 49.7C214.7 49.8 211 51.2 211 56C211 57.3 211.3 57.7 211.4 57.8C211.8 58 213.1 57.1 214 56C214 56 215.2 54.7 215.2 51.9C215.2 51.7 215.3 51.5 215.5 51.3C215.7 51.2 215.9 51.1 216.1 51.1C216.2 51.1 228.4 52.4 237.6 50.5C235.3 40.6 242.3 33.4 242.4 33.4C243.3 32.5 251.7 24.7 256 26.5C257.6 27.2 258.4 29 258.4 31.9C258.4 43.3 242.8 50.3 239.5 51.6C239.6 51.9 239.7 52.2 239.8 52.6C240.2 53.1 242.8 56.5 248.2 56.5C253.9 56.5 264.7 51 265.8 50.5C266.5 49.9 270.4 46.7 272.7 46.7C273.4 46.7 274.1 47 274.6 47.5C275.7 48.6 275.7 50.4 275.7 50.6C275.7 51 275.3 51.4 274.9 51.4C274.5 51.4 274.1 51 274.1 50.6C274.1 50.2 274 49.1 273.5 48.6C273.3 48.4 273.1 48.3 272.8 48.3C271.4 48.3 268.4 50.4 266.8 51.8L266.7 51.9C266.2 52.2 254.7 58.1 248.3 58.1C241.7 58.1 238.7 53.7 238.6 53.5L238.5 53.4C238.3 53 238.2 52.6 238.1 52.2C229.9 53.9 219.8 53.2 216.8 53C216.5 55.9 215.3 57.2 215.2 57.3C214.6 57.7 212.9 59.4 211.4 59.4ZM254.4 27.8C251.5 27.8 246.3 31.7 243.4 34.5C243.1 34.8 237 41.1 239 50.1C242.2 48.7 256.7 42 256.7 31.9C256.7 29.7 256.2 28.4 255.2 28C255 27.9 254.7 27.8 254.4 27.8Z"
                fill="white"
            />
            <path
                d="M295.5 57.9C295.4 57.9 295.2 57.9 295.1 57.8C294.8 57.6 294.7 57.2 294.8 56.9C295.3 55.6 295.6 53.7 295.7 52.3C287.5 57.1 282.4 56.9 282.1 56.9C277.1 56.9 273.9 54.3 272.4 52.9C271.8 54.1 270.6 56 268.8 56.7C267.8 57.1 266.8 57.1 265.8 56.7C264.6 56.2 263.9 55.4 263.7 54.4C263.4 52.5 265.4 50.6 265.6 50.4C265.9 50.1 266.4 50.1 266.7 50.5C267 50.8 267 51.3 266.6 51.6C266.2 52 265 53.2 265.2 54.1C265.3 54.5 265.7 54.9 266.4 55.2C267 55.5 267.6 55.5 268.2 55.2C269.8 54.5 271.1 52 271.4 51.1C271.5 50.8 271.7 50.6 272 50.6C272.3 50.6 272.6 50.7 272.7 50.9C272.7 50.9 276 55.2 282 55.2C282.1 55.2 287.5 55.4 296 50.1C296.2 49.9 296.6 49.9 296.8 50.1C297 50.2 297.2 50.5 297.2 50.8C297.2 50.9 297.1 52.7 296.8 54.6C298.9 52.7 302.2 50 305 48.9C305.2 48.8 305.5 48.8 305.7 49C305.8 49.1 308.5 50.7 310.6 54.2C310.9 54.4 312.2 55.5 315.1 55.5C315.1 55.5 324 54.4 333 49.7C333.4 49.5 333.9 49.6 334 50C334.2 50.4 334.1 50.8 333.7 51C324.4 55.9 315.5 56.9 315.2 57C311.2 57 309.5 55.2 309.4 55.1L309.3 55C307.8 52.5 305.9 51 305.2 50.5C301.2 52.2 296 57.5 295.9 57.6C295.9 57.8 295.7 57.9 295.5 57.9Z"
                fill="white"
            />
            <path
                d="M330.6 60.9C330.5 60.9 330.4 60.9 330.3 60.8C329.9 60.6 329.8 60.2 329.9 59.9L337.2 35.2C337.3 34.8 337.8 34.6 338.2 34.7C338.6 34.8 338.8 35.3 338.7 35.7L332.7 55.8C334.8 53.1 338 49.5 341.9 46.8C342.3 46.6 342.7 46.6 343 47C343.2 47.4 343.2 47.8 342.8 48.1C336 52.8 331.3 60.5 331.3 60.6C331.1 60.7 330.9 60.9 330.6 60.9Z"
                fill="white"
            />
            <path
                d="M400.2 83.1C399.9 81.1 398.4 79.3 395.7 77.8C390.1 74.8 381.5 73 369.2 71.3C367.5 61.2 367.2 50.6 367.2 50.5C367.2 50.2 367 50 366.8 49.8C366.5 49.7 366.2 49.7 366 49.9C365.9 50 357.6 55.7 348.6 55C339.9 54.3 337.7 51.7 337.6 51.7C337.3 51.4 336.9 51.3 336.5 51.6C336.2 51.9 336.1 52.3 336.3 52.7C336.5 53 338.9 55.9 348.4 56.6C356 57.2 362.9 53.6 365.6 52C365.7 55.2 366.1 63.2 367.5 71.2C362.9 70.6 357.7 70 352 69.3C330.7 66.8 246.1 66.7 245.3 66.7C244.2 66.7 184.4 68.5 120.4 72C66.2 75.2 28.6 79.3 6 82.4C83.3 73 243.6 68.2 245.4 68.1C246.3 68.1 330.7 68.2 351.9 70.7C357.8 71.4 363.1 72 367.9 72.7C368.6 76.6 369.6 80.4 370.8 83.5C371 84.1 376.8 97.6 383.9 97.6C390.7 97.6 397.5 89.2 398.1 88.4C398.5 88 400.6 85.9 400.2 83.1ZM397.1 87.3C397 87.3 397 87.4 397.1 87.3C396.9 87.5 390.2 96 384 96C377.9 96 372.4 83 372.3 82.9C371.2 80.1 370.3 76.6 369.6 73C381.5 74.7 389.7 76.4 395.1 79.3C397.4 80.5 398.6 81.9 398.8 83.4C399 85.4 397.1 87.3 397.1 87.3Z"
                fill="white"
            />
            <path
                d="M366 42.8C367.878 42.8 369.4 41.2778 369.4 39.4C369.4 37.5222 367.878 36 366 36C364.122 36 362.6 37.5222 362.6 39.4C362.6 41.2778 364.122 42.8 366 42.8Z"
                fill="#237FE7"
            />
        </svg>
    );
};

const AgilioLogo = styled(AgilioSvg)`
    margin-bottom: 2rem;
    max-width: 250px;
    height: auto;
`;
