import * as React from 'react';
import { Note } from '$types';
import { Note as NoteV2 } from '$types/timeline-v2';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import * as Parts from './Parts';

interface Props {
    note: Note | NoteV2;
    patientName: string;
    representative: {
        photo: string | null;
        name: string | null;
    };
    practiceName: string;
}

export const NoteEvent = (props: Props) => {
    const { note } = props;

    return (
        <Container title="Timeline note event" data-cy="patient-action-note">
            <Parts.Avatar hue="yellow" shade="9" inbound={false} {...props} />
            <Parts.Event role="listitem" data-cy="comm" inbound={false}>
                <Content>{note.content}</Content>
                <Parts.Metadata type="Note" icon="FileText">
                    <Parts.Status
                        status={'Received'}
                        date={note.datetime}
                        disableIndicator
                    />
                </Parts.Metadata>
            </Parts.Event>
        </Container>
    );
};

const Container = styled.div`
    max-width: ${mix.unit({ size: 52 })};
    margin-left: auto;
    display: flex;
    flex-direction: row-reverse;
    gap: ${mix.unit({ size: 2 })};
    width: max-content;
`;

const Content = styled.div`
    ${mix.bg({ hue: 'yellow', shade: '9' })};
    ${mix.padding({ padding: 2 })};
    ${mix.type({ level: 'body2' })};
    ${mix.color({ profile: 'body' })};
    position: relative;
    border-radius: 5px;
    clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 16px, 100% 100%, 0 100%);
    white-space: pre-line;

    &::after {
        ${mix.bg({ hue: 'yellow', shade: '7' })};
        content: '';
        width: ${mix.unit({ size: 2 })};
        height: ${mix.unit({ size: 2 })};
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0 0 0 5px;
    }
`;
