import React, { ComponentType } from 'react';
import { useSelector } from '@xstate5/react';
import { useAuthMode } from '@/config/react';
import * as legacy from './modes/legacy';
import * as concurrent from './modes/concurrent';
import * as sso from './modes/sso';

export function withRequireAuth<T extends Record<string, any>>(
    Component: ComponentType<T>,
) {
    return (props: T) => (
        <RequireAuth>
            <Component {...props} />
        </RequireAuth>
    );
}

export function useAuthentication() {
    const authMode = useAuthMode();

    const legacyAuthed = useSelector(legacy.actor, (snapshot) => {
        return legacy.authenticated(snapshot);
    });

    const concurrentAuthed = useSelector(concurrent.actor, (snapshot) => {
        return concurrent.authenticated(snapshot);
    });

    const ssoAuthed = useSelector(sso.actor, (snapshot) => {
        return sso.authenticated(snapshot);
    });

    return (() => {
        switch (authMode) {
            case 'legacy':
                return legacyAuthed;

            case 'concurrent':
                return concurrentAuthed;

            case 'sso':
                return ssoAuthed;

            default:
                return false;
        }
    })();
}

export function useCredentialsError(): string | null {
    const authMode = useAuthMode();

    const legacyCredentialsError = useSelector(legacy.actor, (snapshot) => {
        if (!snapshot.matches('errored')) {
            return null;
        }

        return snapshot.context.error;
    });

    const concurrentCredentialsError = useSelector(
        concurrent.actor,
        (snapshot) => {
            if (!snapshot.matches({ legacy: 'errored' })) {
                return null;
            }

            return snapshot.context.error;
        },
    );

    return (() => {
        switch (authMode) {
            case 'legacy':
                return legacyCredentialsError;

            case 'concurrent':
                return concurrentCredentialsError;

            case 'sso':
            default:
                return null;
        }
    })();
}

export function useSSOError(): string | null {
    const authMode = useAuthMode();

    const concurrentSSOError = useSelector(concurrent.actor, (snapshot) => {
        if (!snapshot.matches({ sso: 'errored' })) {
            return null;
        }

        return snapshot.context.sso?.error ?? null;
    });

    const ssoError = useSelector(sso.actor, (snapshot) => {
        if (!snapshot.matches('errored')) {
            return null;
        }

        return snapshot.context.error;
    });

    return (() => {
        switch (authMode) {
            case 'legacy':
                throw new Error(
                    'SSO error is unavailable in legacy mode,' +
                        ' indicating a serious programming error',
                );

            case 'concurrent':
                return concurrentSSOError;

            case 'sso':
                return ssoError;

            default:
                return null;
        }
    })();
}

export function useInviteError(): string | null {
    const authMode = useAuthMode();

    const concurrentInviteError = useSelector(
        concurrent.actor,
        (snapshot) => snapshot.context.invite?.error ?? null,
    );

    return (() => {
        switch (authMode) {
            case 'legacy':
                throw new Error(
                    'Invite error is unavailable in legacy mode,' +
                        ' indicating a serious programming error',
                );

            case 'concurrent':
                return concurrentInviteError;

            case 'sso':
            default:
                return null;
        }
    })();
}

export function useInviting(): boolean {
    const authMode = useAuthMode();

    const concurrentInviting = useSelector(concurrent.actor, (snapshot) =>
        snapshot.matches({ legacy: 'authenticated' }),
    );

    return (() => {
        switch (authMode) {
            case 'legacy':
                throw new Error(
                    'Invite error is unavailable in legacy mode,' +
                        ' indicating a serious programming error',
                );

            case 'concurrent':
                return concurrentInviting;

            case 'sso':
            default:
                return false;
        }
    })();
}

export function useInviteSending(): boolean {
    const authMode = useAuthMode();

    const inviteSending = useSelector(
        concurrent.actor,
        (snapshot) =>
            snapshot.matches({ legacy: { authenticated: 'sending' } }) ||
            snapshot.matches({ legacy: { authenticated: 'sent' } }),
    );

    return (() => {
        switch (authMode) {
            case 'legacy':
                throw new Error(
                    'Invite error is unavailable in legacy mode,' +
                        ' indicating a serious programming error',
                );

            case 'concurrent':
                return inviteSending;

            case 'sso':
            default:
                return false;
        }
    })();
}

export function useAuthenticating(): boolean {
    const authMode = useAuthMode();

    const legacyAuthenticating = useSelector(legacy.actor, (snapshot) =>
        snapshot.matches('authenticating'),
    );

    const concurrentAuthenticating = useSelector(
        concurrent.actor,
        (snapshot) =>
            snapshot.matches({ legacy: { authenticated: 'sending' } }) ||
            snapshot.matches({ sso: 'authenticating' }),
    );

    const ssoAuthenticating = useSelector(sso.actor, (snapshot) =>
        snapshot.matches('authenticating'),
    );

    return (() => {
        switch (authMode) {
            case 'legacy':
                return legacyAuthenticating;

            case 'concurrent':
                return concurrentAuthenticating;

            case 'sso':
                return ssoAuthenticating;

            default:
                return false;
        }
    })();
}

type RequireAuthProps = {
    children: React.ReactNode;
    onUnauthenticated?: () => void;
};

export function RequireAuth(props: RequireAuthProps) {
    const { children, onUnauthenticated = () => null } = props;

    const authed = useAuthentication();

    // bingo
    React.useEffect(() => {
        if (!authed) {
            onUnauthenticated();
        }
    }, [authed]);

    if (!authed) {
        return null;
    }

    return <>{children}</>;
}
