const POPUP_WIDTH = 500;
const POPUP_HEIGHT = 666;

interface PopupArgs {
    baseURL: string;
    url: string;
}

/**
 * Authentication popup for SSO
 */
export const popup = async ({ url, baseURL }: PopupArgs) => {
    const x = window.screenX + (window.outerWidth - POPUP_WIDTH) / 2;
    const y = (window.outerHeight - POPUP_HEIGHT) / 2;

    const features = [
        'popup=true',
        `width=${POPUP_WIDTH}`,
        `height=${POPUP_HEIGHT}`,
        `left=${x}`,
        `top=${y}`,
        'resizable=false',
    ].join(',');

    const popupWindow = window.open(url, '_blank', features);

    if (!popupWindow) {
        throw new Error('Pop-up window could not be opened.');
    }

    return new Promise<void>((resolve, reject) => {
        const intervalId = setInterval(() => {
            if (popupWindow.closed) {
                reject(
                    new Error(
                        'The popup was closed before completing authentication.',
                    ),
                );
                clearInterval(intervalId);
            }
        }, 250);

        window.addEventListener('message', (e) => {
            if (e.origin !== baseURL) {
                return;
            }

            const { data } = e;

            if (data === 'leadflo:auth:sso:done') {
                resolve();
                popupWindow?.close();
                clearInterval(intervalId);
            }

            if (data === 'leadflo:auth:sso:failed') {
                reject(new Error('Could not sign-in via AgilioID'));
                popupWindow?.close();
                clearInterval(intervalId);
            }
        });
    });
};
