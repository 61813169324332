import React from 'react';
import { useParams } from 'react-router';
import { PopupStatus } from '$ui/Integrations/Screens';
import { useUIBaseURL } from '@/config/react';

export const ConnectionStatus = () => {
    const { status } = useParams<{ status: 'done' | 'failed' }>();
    const uiBaseURL = useUIBaseURL();

    React.useEffect(() => {
        if (uiBaseURL && window.opener) {
            window.opener.postMessage(
                'leadflo:integration:' + status,
                uiBaseURL,
            );
        }
    }, [uiBaseURL]);

    return <PopupStatus status={status} />;
};
